import React, { useState, useEffect, useMemo } from 'react';
import {Alert, Row, Col, Card, Modal,Table, Dropdown, Nav, Button, Container, Form } from '@themesberg/react-bootstrap';
import Documentation from "../../../components/Documentation";
import './quiz.scss'
import {
  gql,
  useQuery,
  useLazyQuery,
  useMutation
} from '@apollo/client'
import { Datepicker, Timepicker } from './dtpicker';
import Profile3 from "../../../assets/img/team/profile-picture-3.jpg";
import { imageDisplay, PubIcon } from '../helper';
import ImgOptimizer from "../../../middleware/imgOptimizer";
import Questions from './question'
import $ from 'jquery'
import TimeAgo from '../../../middleware/timeAgo';
import { faEllipsisV,  faUserShield, faBullhorn,
  faUserTimes, faTrashAlt, faTrophy, faCheckCircle, faChartLine, faEdit, faList, faQuestionCircle, faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from "react-csv";
import {QRCodeSVG} from 'qrcode.react'


const entry = `
 title
 published
 about
 type
 category
 subCategory
 bounty
 prize
 advertise
 noOfQuestions
percentage
bounty_percentage
 entryUnit
 bountyType
 startTime
 stopTime
 gamepin
 questions {
   id
 }
`;

const input = `
         $title: String!,
         $published: Boolean,
         $gamepin: String,
         $percentage: Int,
         $bounty_percentage: Int,
         $rules: String,
         $about: String,
         $subCategory: String!,
         $category: String!,
         $type: Int!,

         $startTime: String,
         $stopTime: String,

         $participants: Int,
         $reset: Boolean,
         $resetCount: Int,
         $entryUnit: Float,
        $advertise: Boolean,
         $bountyType: Int,
         $bounty: Float,

         $prize: Float,
         $noOfQuestions: Int,
         $retry: Int,
         $retryCount: Int
      `;

const mapped = `{
title: $title,
visual: $visual,
published: $published,
gamepin: $gamepin,
rules: $rules,
about: $about,
subCategory: $subCategory,
 category: $category,
 type: $type,

startTime: $startTime,
stopTime: $stopTime,
advertise: $advertise,
participants: $participants,
resetCount: $resetCount,
reset: $reset,
entryUnit: $entryUnit,
percentage: $percentage,
bounty_percentage: $bounty_percentage,
bountyType: $bountyType,
bounty: $bounty,

prize: $prize,
noOfQuestions: $noOfQuestions,
retry: $retry,
retryCount: $retryCount} `;

const Get_Categories = gql `
  query {
    getCategories{
    id
    name
    createdAt
    subCategories
 } }
`

const GetScores = gql `
	query ($id:ID!){
		getScores(id: $id) {
			point
			reward
			player {
				id
				avatar
				displayName
        point
        cache
        username
        account
			}
      completed
      ansRatio
      arScore
      purchases
      user
      updatedAt
		}
	}
`;

const getGames = gql`
     query {
       games {
        id
        visual
        ${entry}
        approved
        gamepin
        qstLength
        author {
          displayName
        }
         createdAt
       }
     }`

const AddGame = gql`
  mutation AddGame($visual: Upload, ${input}){
    addGame(gameInput:${mapped}){
      id
    }
  }`

  const UpdateGame = gql`
      mutation UpdateGame($id: ID!, $visual: Upload, ${input}){
       updateGame(id: $id, gameInput:${mapped}){
         id, title
  }}`


const RewardGame = gql`
  mutation RewardGame($id: ID){
    rewardGame(id: $id)
}`

const DeleteGame = gql `
      mutation DeleteGame($id: ID!){
       deleteGame(id: $id)}`



       
export default () => {

const [showAlert, setShowAlert] = useState({
    show: false
  });

  useEffect(() => {
    if (showAlert.show) setTimeout(() => setShowAlert({show: false}), 8000)
  }, [showAlert])


  $('.modal-dialog-centered.modal-dialog').addClass('modal-xl')
  const { data, loading, refetch } = useQuery(getGames, {
    onCompleted: data => setShowAlert({
      show: true,
      title: 'Success',
      content: 'Games fetched successfully'
    }),
    onError: err => setShowAlert({
      show: true,
      title: 'Error',
      content: err.message
    })
  });
  const {data:categories, loading: loadingCategories} = useQuery(Get_Categories)

  const [gameData, setGameData] = useState()

  // display uploaded image
  const [upImg, setUpImg]= useState([])

  const imageUploaded = async e=>{
     setFmData({...fmData, visual : e.target.files[0]})
     if(e.target.files.length === 0) return false

     const img = await imageDisplay(e);
      img.onloadend = (e) => {
        setUpImg([img.result])
      };
  }

  const [fmData, setFmData] = useState({})

  const [categoryView, setCategoryView] = useState(null)

  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const [quizData, setQuizData] = useState({
    title: "Add Quiz",
    type: true,
    btnText: "Submit",
    val: {}
  })

  useEffect(() => {
    if(data){
        setGameData(data.games)
        setQuizData({title:"Add Quiz", type:true, btnText:"Submit", val:{}})
    }
  }, [data])



  const inpChange = e =>{      
      const {value, name, type, checked} = e.target;

      if(isNaN(name)){
        fmData[name] = (type === 'number' || !isNaN(value) ? 
          Number(value) : type === 'checkbox'? 
          JSON.parse(checked): name === 'advertise' || name === 'reset' ? JSON.parse(value) : value);
          setFmData({...fmData})
      }
  }


  const [addGame] = useMutation(AddGame,{
    onCompleted: data=> {refetch();
    setShowAlert({
      show: true,
      title: 'Success',
      content: 'Game Added successfully'
    })
    alert("Game Added successfully")
    },
    onError: err => setShowAlert({
      show: true,
      title: 'Error',
      content: err.message
    })
  })

  const [updateGame] = useMutation(UpdateGame, {
    onCompleted: data => {refetch();
    setShowAlert({
      show: true,
      title: 'Success',
      content: 'Game Updated successfully'
    })
    alert("Game Updated successfully")

    },
    onError: err => setShowAlert({
      show: true,
      title: 'Error',
      content: err.message
    })
  })

  const [rewardGame] = useMutation(RewardGame,{
    onCompleted: data => alert(data.rewardGame),
    onError: err => alert(err)
  })

  const [deleteGame] = useMutation(DeleteGame, {
    onCompleted: data => {refetch();
    setShowAlert({
      show: true,
      title: 'Success',
      content: 'Game Deleted successfully'
    })
    alert("Game Deleted successfully")
    },
    onError: err => setShowAlert({
      show: true,
      title: 'Error',
      content: err.message
    })
  })


  const submitHandler = (e) =>{
    e.preventDefault();
    const {startTime, stopTime, advertise} = e.target;
    
    setFmData({...fmData,
          startTime: startTime? startTime.value : quizData.val.startTime,
          stopTime:  stopTime? stopTime.value : quizData.val.stopTime})


     {quizData.type === true? 
      addGame({variables:{...fmData,
          startTime:  startTime.value,
          stopTime: stopTime.value}}) : 
        updateGame({variables:{...fmData,
           startTime: startTime? startTime.value : quizData.val.startTime,
          stopTime:  stopTime? stopTime.value : quizData.val.stopTime}})}
  }


  const getQuiz = async e =>{
   const findQuiz = await gameData.find(q=>q.id===e)
  setUpImg(findQuiz.visual)
  setQuizData({title:`Update ${findQuiz.title}`, type:false, btnText:'submit changes',val:{...findQuiz}})
  setShowDefault(true)
  
  setFmData({...findQuiz, id: findQuiz.id})
  }


  const [toDel, setToDel] = useState(null);
  const [cfm, setCfm] = useState(false);
  

  const deleteQuiz = async () =>{
    try{
      deleteGame({variables:{id: toDel}})
      setCfm(false);
  }
    catch(err){
    }
  }

  const rewardQuiz = (id) =>{
    return rewardGame({variables: {id}});
  }

  const [showQst, setShowQst] = useState({show:false})
  
  const getQsts = e =>{
    setShowQst({game: e, show:!showQst.show})
  }


  const [getGameScores, {data:gameScore, loading: scoreLoading}] = useLazyQuery(GetScores, {
	// pollInterval: 15000,
  pollInterval: 10000,
  onCompleted: data=>{setShowScores(true)},
  onError: err => console.log(err)
})

let headers = [
  { label: "#", key: "index" },
  { label: "PLAYER", key: "player" },
  { label: "EMAIL", key: "email" },
  { label: "SCORE", key: "point" },
  { label: "REWARD", key: "reward" },
  { label: "COMPLETED AT", key: "completed" },
  { label: "ANSWER RATIO", key: "ansRatio" },
  { label: "HINT", key: "hint" },
  { label: "SLASH", key: "slash" },
  { label: "FREEZE", key: "freeze" },
  { label: "REWARDED AT", key: "rewardedAt" },  
];

const [csvData, setCsvData] = useState([]);

const [GS, setGS] = useState([]);
  
useEffect(()=>{
    if (gameScore && gameScore.getScores) {
      const gms =  gameScore.getScores;
      const res = [], csvDataParse = [];

      gms.map((x, index)=>{
        let p = JSON.parse(x.purchases);
        let ar = JSON.parse(x.ansRatio);
        let as = JSON.parse(x.arScore);
        let arr = [];
  
        ar.forEach((x, i)=>{
          arr.push(
            " " +
              (i + 1) +
              ": " +
              new Date(x).toLocaleTimeString() +
              " - " +
              (as[i] !== undefined ? as[i] : " - ") +
              " \n"
          );
        })

        res.push({...x, hint: p.hint, slash: p.slash, freeze: p.freeze, ansRatio: arr.join()})
        csvDataParse.push({
          index:index+1,
          player: x.player.displayName, 
          email: x.player.username,
          point: x.point.toFixed(2),
          reward: Number((x.reward).toFixed(2)).toLocaleString(),
          completed: new Date(x.completed).toLocaleString(),
          ansRatio: res,
          hint: p.hint,
          slash: p.slash,
          freeze: p.freeze,
          rewardedAt:x.updatedAt
        });

      })
      setGS(res)
      setCsvData(csvDataParse);
    };

  }, [gameScore])

const [showScores, setShowScores] = useState(false)
  const handleShow = () => setShowScores(false);


//  const headers = [
//    { label: "#", key: "index" },
//    { label: "Player Name", key: "firstname" },
//    { label: "Score", key: "score" },
//    { label: "Reward", key: "reward" },
//    { label: "Completed", key: "completed" },
//    { label: "Answer Ratio", key: "reward" },
//    { label: "Hint", key: "hint" },
//    { label: "Slash", key: "slash" },
//    { label: "Freeze", key: "freeze" },
//  ];

// const csvData = [

// ];




  return (
    <>
      <Alert
        variant="light"
        show={showAlert.show}
        onClose={() => setShowAlert({ ...showAlert, show: false })}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faBullhorn} className="me-1" />
            <strong>{showAlert.title}! </strong>
            {showAlert.content}
          </div>
          <Button
            variant="close"
            size="xs"
            onClick={() => setShowAlert({ ...showAlert, show: false })}
          />
        </div>
      </Alert>

      {/* {showQst && <Questions />} */}
      {showQst.show && (
        <Questions show={showQst.show} game={showQst.game} toggle={getQsts} />
      )}

      <Container className="px-0">
        <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
          <Col className="d-block mb-4 mb-md-0">
            <h1 className="h2">Quiz Dome</h1>
            <p className="mb-0"></p>
          </Col>
        </Row>

        <Row>
          {!gameData
            ? "loading"
            : gameData.map((game, i) => (
                <Col
                  key={i}
                  xs={12}
                  className="mb-4 col-xl-3 col-lg-4 col-md-6 col-sm-6"
                >
                  <Card className={"quiz-card"}>
                    <Card.Header className="header">
                      <img src={game.visual} />
                      {/* <ImgOptimizer img={game.visual} /> */}

                      <div className="pub-btn">
                        {String(game.published) === "true" && (
                          <input
                            type="checkbox"
                            className="toggle"
                            name="published"
                            id={`toggle${i}`}
                            defaultChecked={true}
                            disabled
                          />
                        )}
                        {String(game.published) === "false" && (
                          <input
                            type="checkbox"
                            className="toggle"
                            name="published"
                            id={`toggle${i}`}
                            defaultChecked={false}
                            disabled
                          />
                        )}

                        <label htmlFor={`toggle${i}`} className="toggleWrapper">
                          <div className="toggle"></div>
                        </label>
                      </div>

                      {/* <PubIcon className="pub-btn" id={i} defaultValue={game.published} /> */}

                      <p className="countdown">
                        {/* {game.stopTime} */}
                        <TimeAgo
                          time={
                            new Date().getTime() >
                            new Date(game.startTime).getTime()
                              ? game.stopTime
                              : game.startTime
                          }
                        />
                      </p>
                    </Card.Header>
                    <Card.Body className="body">
                      <div className="header">
                        <div className="title">
                          <h4>{game.title}</h4>
                          {/* <FontAwesomeIcon icon={faEllipsisV}  />  */}
                          <div className="options">
                            <button
                              onClick={() => {
                                $(`.opt-drp`).not(`#opt${i}`).hide();

                                $(`#opt${i}`).is(":hidden")
                                  ? $(`#opt${i}`).show()
                                  : $(`#opt${i}`).hide();
                              }}
                              aria-haspopup="true"
                              aria-expanded="false"
                              type="button"
                              className="text-dark m-0 p-0 dropdown-toggle dropdown-toggle-split btn btn-link"
                            >
                              <span className="icon icon-sm">
                                <FontAwesomeIcon icon={faEllipsisV} />{" "}
                              </span>
                            </button>

                            <div
                              id={"opt" + i}
                              className="opt-drp dropdown-menu shw"
                              style={{
                                position: "absolute",
                                margin: "0px",
                                transform: "translate(-169.5px, 30px)",
                              }}
                              data-popper-reference-hidden="false"
                              data-popper-escaped="false"
                              data-popper-placement="bottom-end"
                            >
                              <a
                                className="text-danger dropdown-item"
                                role="button"
                                onClick={(e) => {
                                  setCfm(true);
                                  setToDel(game.id);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} /> Delete
                              </a>

                              <br />

                             <div>
                             <QRCodeSVG value={'https://brainballer.com/resolve/encrypted?code='+game.gamepin} />
                            </div>

                              <a
                                className="dropdown-item"
                                role="button"
                                onClick={(e) => getQuiz(game.id)}
                              >
                                <FontAwesomeIcon icon={faEdit} /> Edit
                              </a>

                             

                              <a
                                className="dropdown-item"
                                role="button"
                                onClick={(e) => {
                                  getGameScores({ variables: { id: game.id } });
                                  setShowScores(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faChartLine} />{" "}
                                Leaderboard
                              </a>

                              <a
                                className="dropdown-item"
                                role="button"
                                onClick={(e) => rewardQuiz(game.id)}
                              >
                                <FontAwesomeIcon icon={faCoins} /> Reward Quiz
                              </a>
                            </div>
                          </div>
                        </div>
                        <p className="category">
                          {game.category} : {game.subCategory}
                          <br />
                          <small className="qstCount">
                            {game.noOfQuestions +
                              " out of " +
                              game.qstLength +
                              " questions"}
                          </small>{" "}
                        </p>
                      </div>
                      <div className="about">
                        <p className="bounty">
                          N{game.bounty} | {game.no} Qsts
                          <br />
                          <small className="gamepin">
                            {game.about}
                            <br />
                            Help users find this game faster:{" "}
                            <b>{game.gamepin}</b>
                          </small>
                        </p>
                      </div>
                    </Card.Body>
                    <Card.Footer className="footer">
                      <div className="btn">
                        <button
                          className="add-qsts"
                          onClick={(e) => getQsts(game.id)}
                        >
                          <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                        </button>
                      </div>
                      <p className="author">
                        {game.author.displayName} |{" "}
                        <TimeAgo time={game.createdAt} />{" "}
                      </p>
                    </Card.Footer>
                  </Card>{" "}
                </Col>
              ))}
        </Row>

        <Button
          variant="primary"
          className="add-btn my-3"
          onClick={() => {
            setUpImg([]);
            setQuizData({
              title: "Add Quiz",
              type: true,
              btnText: "Submit",
              val: {},
            });
            setFmData({});
            setShowDefault(true);
          }}
        >
          {" "}
          Add{" "}
        </Button>

        <Modal
          as={Modal.Dialog}
          centered
          show={showDefault}
          onHide={handleClose}
          className="modal-tertiary"
        >
          <Modal.Header>
            <Modal.Title className="h6">{quizData.title}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>

          <Form onSubmit={submitHandler}>
            <Modal.Body>
              <Form.Group className="quizImg">
                <img src={upImg} alt="quiz image" />
              </Form.Group>

              {/* <Form.Group>
                          <Form.Check
                          defaultChecked
                          type="radio"
                          defaultValue="option1"
                          label="Video Footage"
                          name="visualType"
                          id="radio1"
                          htmlFor="radio1"
                          />

                        <Form.Check
                          type="radio"
                          defaultValue="option2"
                          label="Visual Image "
                          name="visualType"
                          id="radio2"
                          htmlFor="radio2"
                          />
                      </Form.Group>

             <Form.Group className="mb-3">
                <Form.Label>Footage Link</Form.Label>
                <Form.Control type="text" name="visual" isInValid placeholder="Enter Footage Link" />
              </Form.Group> */}

              <Form.Group className="mb-3">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  name="visual"
                  placeholder="upload image"
                  onChange={imageUploaded}
                />
              </Form.Group>

              <Form.Group className="mb-3 row">
                <Col xs={2} className="col-lg-2 col-md-2 col-sm-2">
                  <Form.Label>
                    {fmData.published ? "Published" : "Unstaged"}
                  </Form.Label>
                  <div className="published-btn">
                    <input
                      type="checkbox"
                      className="toggle"
                      name="published"
                      id={`toggle${quizData.val.id}`}
                      defaultChecked={quizData.val.published ? true : false}
                      onChange={inpChange}
                    />
                    <label
                      htmlFor={`toggle${quizData.val.id}`}
                      className="toggleWrapper"
                    >
                      <div className="toggle"></div>
                    </label>
                  </div>
                  {/* <PubIcon className="published-btn" id={quizData.val.id} defaultValue={quizData.val.published } onChange={inpChange}/> */}

                  {/* <Form.Control type="text" name="title" isInValid placeholder=" " defaultValue={quizData.val.title} onChange={inpChange}/> */}
                </Col>

                <Col xs={10} className="col-lg-10 col-md-10 col-sm-10">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder=" "
                    max="16"
                    maxValue="16"
                    defaultValue={quizData.val.title}
                    onChange={inpChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group className="row mb-3">
                <Col xs={12} className="col-lg-6 col-md-6 col-sm-6">
                  <Form.Label>Game Type</Form.Label>
                  <Form.Select
                    name="type"
                    onChange={inpChange}
                    defaultValue={quizData.val.type}
                  >
                    <option defaultValue hidden disabled>
                      Choose Quiz Type
                    </option>
                    <option value={1}>Mega Challenge</option>
                    <option value={2}>Classic Challenge</option>
                    <option value={3}>Regular Challenge</option>
                    <option value={4}>Free Challenge</option>
                  </Form.Select>
                </Col>
                <Col xs={12} className="col-lg-6 col-md-6 col-sm-6">
                  <Form.Label>Category</Form.Label>
                  <Form.Select
                    name="category"
                    onChange={(e) => {
                      const $_sc = categories.getCategories.find(
                        (sc) => sc.name === e.target.value
                      );
                      setCategoryView({
                        name: $_sc.name,
                        data: $_sc.subCategories,
                      });
                      inpChange(e);
                    }}
                    defaultValue={
                      quizData.val ? quizData.val.category : "default"
                    }
                  >
                    {loadingCategories ? (
                      <option disabled>loading</option>
                    ) : (
                      <>
                        <option value="default">Choose Quiz Category</option>
                        {categories.getCategories.map((e, i) => (
                          <option key={i}>{e.name}</option>
                        ))}
                      </>
                    )}
                    {/* <option>One</option>
                    <option>Two</option>
                    <option>Three</option> */}
                  </Form.Select>
                </Col>

                <Col xs={12} className="col-lg-6 col-md-6 col-sm-6">
                  <Form.Label>SubCategory</Form.Label>
                  <Form.Select
                    name="subCategory"
                    onChange={inpChange}
                    defaultValue={
                      quizData.val ? quizData.val.subCategory : "default"
                    }
                  >
                    <option defaultSelected value="default">
                      Choose {categoryView && categoryView.name} Sub-category
                    </option>
                    {quizData.val &&
                      quizData.val.subCategory &&
                      !categoryView && (
                        <option> {quizData.val.subCategory} </option>
                      )}
                    {categoryView &&
                      categoryView.data.map((sc, i) => (
                        <option key={i}>{sc}</option>
                      ))}
                  </Form.Select>
                </Col>

                <Col xs={12} className="col-lg-6 col-md-6 col-sm-6">
                  <Row>
                    {quizData.val.type !== undefined && (
                      <Col xs={12} className="col-lg-4 col-md-4 col-sm-4">
                        <Form.Label>
                          Reset
                          {quizData.val.reset && (
                            <code>
                              Warning! all existing scores will be erased
                            </code>
                          )}
                        </Form.Label>
                        <Form.Select
                          name="reset"
                          onChange={inpChange}
                          defaultValue={false}
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </Form.Select>
                      </Col>
                    )}
                    <Col xs={12} className="col-lg-4 col-md-4 col-sm-4">
                      <Form.Label>Feature this Quiz</Form.Label>
                      <Form.Select
                        name="advertise"
                        onChange={inpChange}
                        defaultValue={quizData.val.advertise}
                      >
                        <option defaultValue hidden disabled>
                          Advertise Quiz
                        </option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Col>
                    <Col xs={12} className="col-lg-4 col-md-4 col-sm-4">
                      <Form.Label>Entry Fee</Form.Label>
                      <Form.Control
                        type="number"
                        name="entryUnit"
                        min="0"
                        placeholder="100"
                        onChange={inpChange}
                        defaultValue={quizData.val.entryUnit}
                      />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>

              {quizData.val.type !== 4 && (
                <>
                  {" "}
                  <Form.Group className="row mb-3">
                    <Col xs={12} className="col-lg-3 col-md-3 col-sm-6">
                      <Form.Label>participants</Form.Label>
                      <Form.Control
                        type="number"
                        name="participants"
                        min="0"
                        placeholder="1000 players"
                        onChange={inpChange}
                        defaultValue={quizData.val.participants}
                      />
                    </Col>
                    {quizData.val.type !== 4 && (<>
                      <Col xs={12} className="col-lg-3 col-md-3 col-sm-3">
                        <Form.Label>Reward Players (%)</Form.Label>
                        <Form.Control
                          type="number"
                          name="percentage"
                          min="0"
                          max="100"
                          placeholder="reward players"
                          onChange={inpChange}
                          defaultValue={quizData.val.percentage}
                        />
                      </Col>
                      <Col xs={12} className="col-lg-3 col-md-3 col-sm-3">
                        <Form.Label>Reward Percentage(%)</Form.Label>
                        <Form.Control
                          type="number"
                          name="bounty_percentage"
                          min="0"
                          max="100"
                          placeholder="reward percentage"
                          onChange={inpChange}
                          defaultValue={quizData.val.bounty_percentage}
                        />
                      </Col>
                      </>
                    )}
                    {quizData.val.type !== undefined && (
                      <>
                        <Col xs={12} className="col-lg-3 col-md-3 col-sm-3">
                          <Form.Label>
                            No. of Qstns (
                            {quizData.val.qstLength + " questions"})
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="noOfQuestions"
                            max={quizData.val.qstLength}
                            placeholder="number of questions"
                            onChange={inpChange}
                            defaultValue={quizData.val.noOfQuestions}
                          />
                        </Col>
                      </>
                    )}
                  </Form.Group>{" "}
                  <Form.Group className="row mb-3">
                    <Col xs={12} className="col-lg-6 col-md-6 col-sm-6">
                      <Form.Label>Reward Mode</Form.Label>
                      <Form.Select
                        name="bountyType"
                        onChange={inpChange}
                        defaultValue={quizData.val.bountyType}
                      >
                        <option defaultValue hidden disabled>
                          Choose Reward Mode
                        </option>
                        <option value={0}>Admin Rewarded</option>
                        <option value={1}>Player Count Dependent</option>
                        <option value={2} disabled>
                          Corporate Count Dependent
                        </option>
                      </Form.Select>
                    </Col>

                    <Col xs={12} className="col-lg-6 col-md-6 col-sm-6">
                      <Form.Label>Reward Bounty</Form.Label>
                      <Form.Control
                        type="number"
                        name="bounty"
                        placeholder="50,000"
                        defaultValue={quizData.val.bounty}
                        onChange={inpChange}
                        min="0"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group className="row mb-3">
                    <Col xs={12} className="col-lg-6 col-md6 col-sm-6">
                      <Form.Label>Start Date</Form.Label>
                      <Datepicker
                        name={"startTime"}
                        onChange={inpChange}
                        defaultValue={quizData.val.startTime}
                      />
                    </Col>

                    <Col xs={12} className=" col-lg-6 col-md6 col-sm-6">
                      <Form.Label>Stop Date</Form.Label>
                      <Datepicker
                        name={"stopTime"}
                        onChange={inpChange}
                        validity={fmData.startTime}
                        defaultValue={quizData.val.stopTime}
                      />
                    </Col>
                  </Form.Group>
                </>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Quiz Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="about"
                  defaultValue={quizData.val.about}
                  rows="3"
                  onChange={inpChange}
                />
                <Form.Control.Feedback type="valid">
                  Looks good!
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" type="submit">
                {quizData.btnText}
              </Button>
              <Button
                variant="link"
                className="text-gray ms-auto"
                onClick={handleClose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          as={Modal.Dialog}
          centered
          show={showScores}
          onHide={handleShow}
          className="modal-tertiary"
        >
          <Modal.Header>
            <Modal.Title className="h6">Leaderboard</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleShow} />
          </Modal.Header>
          <Modal.Body>
            <>
              <CSVLink
                data={csvData}
                headers={headers}
                className="btn btn-info"
                target="_blank"
                filename={"leaderboard.csv"}
              >
                Export Leaderboard
              </CSVLink>
              <Table
                responsive
                className="table-centered table-nowrap rounded mb-0"
              >
                <thead className="thead-light">
                  <tr>
                    <th
                      rowspan="2"
                      align="center"
                      valign="top"
                      className="border-0"
                    >
                      #
                    </th>
                    <th
                      rowspan="2"
                      align="center"
                      valign="top"
                      className="border-0"
                    >
                      Player
                    </th>
                    <th
                      rowspan="2"
                      align="center"
                      valign="top"
                      className="border-0"
                    >
                      Score
                    </th>
                    <th
                      rowspan="2"
                      align="center"
                      valign="top"
                      className="border-0"
                    >
                      Reward
                    </th>
                    <th
                      rowspan="2"
                      align="center"
                      valign="top"
                      className="border-0"
                    >
                      Completed
                    </th>
                    <th
                      rowspan="2"
                      align="center"
                      valign="top"
                      className="border-0"
                    >
                      Answer Ratio
                    </th>
                    <th
                      align="center"
                      valign="top"
                      className="border-0"
                      colspan="3"
                    >
                      Purchases
                    </th>
                    <th
                      rowspan="2"
                      align="center"
                      valign="top"
                      className="border-0"
                    >
                      Rewarded At
                    </th>
                  </tr>

                  <tr>
                    <th align="center" valign="top">
                      Hint
                    </th>
                    <th align="center" valign="top">
                      Slash
                    </th>
                    <th align="center" valign="top">
                      Freeze
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {GS.map((sc, i) => (
                    <tr key={`page-traffic-${sc.id}`}>
                      <td align="center" valign="top">
                        {i + 1}{" "}
                      </td>
                      <td align="center" valign="top">
                        <a className="d-flex align-items-center card-link">
                          <img
                            src={`https://res.cloudinary.com/brainballer/image/upload/v1621775917/avatar/${
                              sc.player.avatar ? sc.player.avatar : "def"
                            }.png`}
                            className="user-avatar rounded-circle me-3"
                          />
                          <div className="d-block">
                            <span className="fw-bold">
                              {sc.player.displayName}{" "}
                              {sc.player.verified && (
                                <FontAwesomeIcon icon={faCheckCircle} />
                              )}{" "}
                            </span>
                            <div className="small text-gray">
                              {sc.player.name}
                            </div>
                            <div className="small text-gray">
                              {sc.player.cache === "admin" && (
                                <span className={`text-danger`}>● </span>
                              )}
                              {sc.player.account == 1 && (
                                <span className={`text-warning`}>● </span>
                              )}
                              {sc.player.username}
                            </div>
                          </div>
                        </a>
                      </td>
                      <td align="center" valign="top">
                        {sc.point.toFixed(2)} pts
                      </td>
                      <td align="center" valign="top">
                        {" "}
                        ₦{Number(sc.reward.toFixed(2)).toLocaleString()}{" "}
                      </td>
                      <td align="center" valign="top">
                        {" "}
                        {new Date(sc.completed).toLocaleString()}{" "}
                      </td>

                      <td align="center" valign="top">
                        <p className="ansRatio">
                          {sc.ansRatio.split(",").map((a, i) => {
                            return (
                              <span
                                key={i}
                                style={{ width: "100%", pointer: "cursor" }}
                              >
                                {a}
                              </span>
                            );
                          })}{" "}
                        </p>
                      </td>
                      {/* <td align="center" valign="top">   */}

                      <td align="center" valign="top">
                        {sc.hint}
                      </td>
                      <td align="center" valign="top">
                        {sc.slash}
                      </td>
                      <td align="center" valign="top">
                        {sc.freeze}
                      </td>

                      {/* </td> */}
                      <td align="center" valign="top">
                        {" "}
                        {new Date(sc.updatedAt).toLocaleString()}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          </Modal.Body>
        </Modal>

        <Modal
          as={Modal.Dialog}
          centered
          show={cfm}
          onHide={(e) => setCfm(false)}
          className="modal-tertiary"
        >
          <Modal.Body>
            <div className="confirm-element">
              <h3>Are you Sure?</h3>
              <div className="btn-div">
                <button className="btn-cancel" onClick={(e) => setCfm(false)}>
                  Cancel
                </button>
                <button
                  className="btn-submit"
                  onClick={(e) => deleteQuiz()}
                  aria-label="Submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};
