import React from 'react'
import { InMemoryCache, Reference, makeVar } from '@apollo/client';
import {gql} from '@apollo/client'

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    cartItems: [ID!]!
  }
`;

// Initializes to true if localStorage includes a 'token' key,
// false otherwise
export const isLoggedInVar = makeVar(!!localStorage.getItem("token"));
// Initializes to an empty array
export const authVar = makeVar(JSON.parse(localStorage.getItem("auth")));




export const cache = new InMemoryCache({
    addTypename: false,
    typePolicies: {
        Query: {
            fields: {
                isLoggedIn: {
                    read() {
                        return isLoggedInVar();
                    }
                },
                auth: {
                    read() {
                        return authVar();
                    }
                },
                launches: {
                    // ...field policy definitions...
                }
            }
        }
    }
});