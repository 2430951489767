import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, BrowserRouter } from "react-router-dom";
import "./scss/volt.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import {createUploadLink} from 'apollo-upload-client'
import {typeDefs, cache} from './middleware/cache'
import {gql, ApolloProvider, ApolloClient, ApolloLink, useQuery} from '@apollo/client'
/* global $ */
import {WebSocketLink} from '@apollo/client/link/ws';
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import Signin from "./pages/examples/Signin";

const upLink = createUploadLink({
  uri: 'https://brainballer.com/'
  // uri: 'http://localhost:4000/'
})

const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${JSON.parse(token)}` : "",
    }
  }
});

const wsLink = new WebSocketLink({
    uri: `wss://brainballer.com/`,
    // uri: `ws://localhost:4000/`,
    options:{
        lazy: true,
        reconnect: true,      
    }
})

const link = ApolloLink.split(({query})=>{
  const def = getMainDefinition(query);
  return(
    def.kind === 'OperationDefinition' &&
    def.operation === 'subscription'
  )
}, wsLink, authLink.concat(upLink))

const client = new ApolloClient({
    link, cache, typeDefs
})


const IS_LOGGED_IN = gql `
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

function IsLoggedIn() {
  const { data } = useQuery(IS_LOGGED_IN);
  return data.isLoggedIn ? <HomePage IsLoggedIn={data.isLoggedIn}  />: <Signin />
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ScrollToTop />
      <IsLoggedIn />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

