import React, { useEffect, useState } from 'react'

const TimeAgo = (props) => {

const [time, setTime] = useState(null)

    useEffect(() => {
       timeFunction()
    }, [])

    const timeFunction = () =>{

        const options = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }
    const transform = new Date(props.time).toLocaleString('en-US', options)

    var countDownDate = new Date(transform).getTime();

    var x = setInterval( () => {

        var now = new Date().getTime();
        var res;
        var distance = (now >countDownDate ? now - countDownDate : countDownDate - now);

       

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

           /////
           if (days != 0) {
                if (days == 1) {
                    res = days + " day "
                } else {
                    res = days + " days "
                }
           } else if (days === 0 && hours != 0) {
                if (hours == 1) {
                    res = hours + "hr "
                } else {
                    res = hours + " hrs "
                }
           } else if (days === 0 && hours === 0 && minutes !== 0) {
                if(minutes == 1){
                 res =  minutes + " min "
                }else{
                 res =  minutes + " mins "
                }
           } else if (days === 0 && hours === 0 && minutes === 0 && seconds !== 0 ) {
               
                    res = " few seconds "
                
        }
           ////

            if (countDownDate > now) {
                setTime(res)
            }else{
                setTime(res + " ago")
            }
    }, 1000);
}

return(
    <>
        {time && <span className='timeago'>{time}</span>}
    </>
)
}

export default TimeAgo;