import React,{useState, useEffect} from 'react'
import { Row, Col, Card, Form } from '@themesberg/react-bootstrap';
import Table from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
// import entries from "src/data/jobs";
import entries from '../../../data/transactions'



const customTotal = (from, to, size) => (
  <div>
    Showing {from} to {to} of {size} entries
  </div>
);

const customSizePerPage = (props) => {
  const { options, currentSizePerPage, onSizePerPageChange } = props;

  const onPageChange = (e) => {
    const page = e.target.value;
    onSizePerPageChange(page);
  }

  return (
    <Row>
      <Col xs="auto">
        <Form.Select value={currentSizePerPage} onChange={onPageChange} className="pe-5">
          {options.map(o => (
            <option key={o.page} value={o.page}>
              {o.text}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col xs="auto" className="d-flex align-items-center">
        entries per page
      </Col>
    </Row>
  );
};

const { ExportCSVButton } = CSVExport;


const DataTable =props=>{
return(
  <ToolkitProvider
    keyField="id"
    search={true}
    exportCSV= {true}
    columns={props.columns}
    data={props.data}
    wrapperClasses="table-responsive"
    rowClasses={props.rowClasses}
  >
    {({ baseProps, searchProps, csvProps }) => (
      <Paginator.PaginationProvider pagination={
        Pagination({
          custom: true,
          showTotal: true,
          alwaysShowAllBtns: true,
          totalSize: props.data.length,
          paginationTotalRenderer: customTotal,
          sizePerPageRenderer: customSizePerPage
        })
      }>
        {({ paginationProps, paginationTableProps }) => (
          <Card>
            <div className="table-responsive py-2">
              <Card.Header>
                <Row>
                  <Col xs={12} md={6} className="py-1">
                    <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                  </Col>
                  <Col xs={12} md={6} className="d-flex justify-content-md-end py-1">
                    <Search.SearchBar {...searchProps} />
                    <ExportCSVButton {...csvProps}>Export</ExportCSVButton>
                  </Col>
                </Row>
              </Card.Header>

              <Table {...baseProps} {...paginationTableProps} />

              <Card.Footer>
                <Row>
                  <Col xs={12} md={6} className="d-flex align-items-center py-1">
                    <Paginator.PaginationTotalStandalone {...paginationProps} />
                  </Col>
                  <Col xs={12} md={6} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                    <Paginator.PaginationListStandalone {...paginationProps} />
                  </Col>
                </Row>
              </Card.Footer>
            </div>
          </Card>
        )}
      </Paginator.PaginationProvider>
    )}
  </ToolkitProvider>
)
        };

export default DataTable;