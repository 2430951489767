import React,{useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCog,
  faHome,
  faCheckCircle,
  faUserAltSlash,
  faSearch,
  faUserSlash
} from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Modal, Button, ButtonGroup, Breadcrumb, Container, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { TransactionsTable } from "../components/Tables";
import {
  gql,
  useQuery,
  useMutation,
  useSubscription
} from '@apollo/client'
import DataTable from './components/userInformation/dataTable'
import TimeAgo from "../middleware/timeAgo";
import $ from 'jquery'

const getTransactions = gql`
  query {
     finances {
       id
       domain
       amount
       reference
        user {
          avatar
          displayName
          name
          username
        }
       created_at
       paid_at
       response
     }
  }` 


  const getWithdrawals = gql `
  query Withdrawals($type: String) {
     withdrawals(type: $type) {
       id
       status
       amount
       createdAt
        user {
          avatar
          displayName
          bank
          accountnumber
          name
          username
        }
     }
  }`


  const Mass_Update = gql `
    mutation MassUserAction($type: Int!, $action: Boolean,
       $users: String, $amount: Float, $content: String) {
        massUserAction(type: $type, users: $users, action: $action,
          amount: $amount, content: $content)
    }
    `

// const getUser = gql`
//     query User($id: ID){
//       user(id: $id){
//         displayName
//         games {
//           published
//         }
//         friends {
//           displayName
//         }
//       }
//     }
// `

export default () => {

  
  const checkBox = [];
  const [checkeds, setCheckeds] = useState({state: false, count: 0})
  const [fChecks, setFChecks] = useState([])

  const checkedHandlers = (e, id) =>{
    const chks = $(".tableChecks").find("input:checkbox:checked");

    console.log(chks.length)
    // setCheckeds({...checkeds, count: chks.length});

    if(chks.length > 0){
      setCheckeds({count: chks.length, state: true});
    }else{
      setCheckeds({state: false});
    }

    if(e.target.checked){
      checkBox.push(id)
    } else if (!e.target.checked) {
      const index = checkBox.indexOf(id)
      if (index > -1) {
        checkBox.splice(index, 1)
      }
    }

    setFChecks(checkBox)
    console.log(checkBox)
  }
  
const columns = [
    { dataField: "id", text: "ID", hidden: true },
    { dataField: "reference", text: "Transaction Reference" },
    { dataField: "user", text: "Profile",  formatter:(cell) =><>
            <a className="d-flex align-items-center card-link">
         <img src={`https://res.cloudinary.com/brainballer/image/upload/v1621775917/avatar/${cell.avatar?cell.avatar:'def'}.png`} className="user-avatar rounded-circle me-3" />
            <div className="d-block">
              <span className="fw-bold">{cell.displayName}</span>
              <div className="small text-gray">{cell.name}</div>
              <div className="small text-gray">{cell.username}</div>
            </div>
          </a>
    </>, 
  filterValue: (cell) => {
    let arr = Object.keys(cell).map((k) => cell[k])
    return arr;
  }, sort: true,
    csvFormatter: (cell) => `${cell.displayName + ' - '+ cell.name + ' - '+ cell.username}`
},
    { dataField: "time", text: "Initiated, Completed",  formatter:(cell) =><>
            <p>{new Date(cell.start).toLocaleString()}</p>,
            <p className="font-weight-bold mr-2">
              {new Date(cell.completed).toLocaleString()}</p>
    </>, 
  filterValue: (cell) => {
    let arr = Object.keys(cell).map((k) => cell[k])
    return arr;
  }, sort: true,
    csvFormatter: (cell) => `${new Date(cell.start).toLocaleString() + ' - '+ new Date(cell.completed).toLocaleString()}`

},
    { dataField: "status", text: "Amount, Status",  
      formatter:(cell) =><>
            <p className="font-weight-bold mr-2">
              {cell.response}
              </p>
            <p>{cell.amount}</p>
    </>, 
  filterValue: (cell) => {
    let arr = Object.keys(cell).map((k) => cell[k])
    return arr;
  }, 
  sort: true, 
    csvFormatter: (cell) => `${cell.response + ' - '+cell.amount}`

}];


const columnWD = [
    { dataField: "id", text: " ", searchable: false,  

    formatter: (cell) =><div className={`dashboard-check`}>
      <Form.Check className="tableChecks" onChange={e=>checkedHandlers(e, cell)} value={cell}/>
      </div>, csvExport: false
    },
    { dataField: "createdAt", text: "Date | Time", 
  formatter: cell => <span> {new Date(cell).toLocaleString()}
  </span> },
   { dataField: "amount", text: "Amount", 
    formatter:(cell)=> <span> {cell} </span>,
    filterValue: (cell) => {
      let arr = Object.keys(cell).map((k) => cell[k])
      return arr;
    }, sort: true,
    csvFormatter: (cell) => `${cell}` 
  },
  

   { dataField: "user", text: "Bank Name", 
  formatter: cell => <span>
       <div className="d-block">
              <span className="fw-bold">{cell.bank == 1?
                'Access Bank' : cell.bank == 2 ? 'Access Bank (Diamond)' :
                cell.bank == 3 ? 'ALAT by WEMA' : cell.bank == 4 ? 'Amju Unique MFB':
                cell.bank == 5 ? 'ASO savings & Loans' : cell.bank == 6 ? 'Bainescredit MFB' :
                cell.bank == 7 ? 'Bowen MFB' : cell.bank == 8 ? 'Carbon' : cell.bank == 9 ? 'CEMCS MFB' :
                cell.bank == 10 ? 'Citibank Nigeria' : cell.bank == 11 ? 'Coronation Merchant Bank' :
                cell.bank == 12 ? 'Ecobank' : cell.bank == 13 ? 'Ekondo MFB' : cell.bank == 14 ? 'Eyowo' :
                cell.bank == 15 ? 'Fidelity Bank' : cell.bank == 16 ? 'Firmus MFB' : cell.bank == 17 ? 'First Bank' :
                cell.bank == 18 ? 'FCMB' : cell.bank == 19 ? 'FSDH Merchant Bank' : cell.bank == 20 ? 'Globus Bank' :
                cell.bank == 21 ? 'GoMoney' : cell.bank == 22 ? 'Guaranty Trust Bank' : cell.bank == 23 ? 'Hackman MFB' :
                cell.bank == 24 ? 'Hasal MFB' : cell.bank == 25 ? 'Heritage Bank' : cell.bank == 26 ? 'Ibile MFB' :
                cell.bank == 27 ? 'Infinity MFB' : cell.bank == 28 ? 'Jaiz Bank' : cell.bank == 29 ? 'Keystone Bank' :
                cell.bank == 30 ? 'Kredi Money' : cell.bank == 31 ? 'Kuda Bank' : cell.bank == 32 ? 'Lagos Building Investment Comp. Plc.' :
                cell.bank == 33 ? 'Links MFB' : cell.bank == 34 ? 'Mayfair MFB' : cell.bank == 35 ? 'Mint MFB' :
                cell.bank == 36 ? 'PalmPay' : cell.bank == 37 ? 'Parallex Bank' : cell.bank == 38 ? 'Parkway ReadyCash' :
                cell.bank == 39 ? 'Paycom' : cell.bank == 40 ? 'Petra MFB' : cell.bank == 41 ? 'Polaris Bank' :
                cell.bank == 42 ? 'Providus Bank' : cell.bank == 43 ? 'Rand Mercahnt Bank' : cell.bank == 44 ? 'Rubies MFB' :
                cell.bank == 45 ? 'Sparkle MFB' : cell.bank == 46 ? 'Stanbic IBTC Bank' : cell.bank == 47 ? 'Standard Chartered Bank' :
                cell.bank == 48 ? 'Sterling Bank' : cell.bank == 49 ? 'Suntrust Bank' : cell.bank == 50 ? 'TAJ Bank' :
                cell.bank == 51 ? 'TCF MFB' : cell.bank == 52 ? 'Titan Bank' : cell.bank == 53 ? 'Union Bank' : cell.bank == 54 ? 'UBA' :
                cell.bank == 55 ? 'Unity Bank' : cell.bank == 56 ? 'VFD MFB' : cell.bank == 57 ? 'Wema Bank' : cell.bank == 58 ? 'Zenith Bank' :
                cell.bank
                }</span>
            </div>
    </span> },
     { dataField: "user", text: "Account Number", 
    formatter:(cell)=> <span> {cell.accountnumber} </span>,
    filterValue: (cell) => {
      let arr = Object.keys(cell).map((k) => cell[k])
      return arr;
    }, sort: true,
    csvFormatter: (cell) => `${cell}` 
  },
    { dataField: "user", text: "Profile",  formatter:(cell) =><>
            <a className="d-flex align-items-center card-link">
         <img src={`https://res.cloudinary.com/brainballer/image/upload/v1621775917/avatar/${cell.avatar?cell.avatar:'def'}.png`} className="user-avatar rounded-circle me-3" />
            <div className="d-block">
              <span className="fw-bold">{cell.displayName}</span>
              <div className="small text-gray">{cell.name}</div>
              <div className="small text-gray">{cell.username}</div>
            </div>
          </a>
    </>, 
  filterValue: (cell) => {
    let arr = Object.keys(cell).map((k) => cell[k])
    return arr;
  }, sort: true,
    csvFormatter: (cell) => `${cell.displayName + ' - '+ cell.name + ' - '+ cell.username}`
},
    { dataField: "status", text: "Status", 
    formatter:(cell)=> <span> {cell === 0?'pending':cell===1?'rejected':cell===2?'paid':cell===3 && 'cancelled'} </span>,
    filterValue: (cell) => {
      let arr = Object.keys(cell).map((k) => cell[k])
      return arr;
    }, sort: true,
     csvFormatter: (cell) => `${cell === 0?'pending':cell===1?'rejected':cell===2?'paid':cell===3 && 'cancelled'}`
  },

]


  const {data, 
    loading, 
    refetch: refetchTrans} = useQuery(getTransactions)



  if(data){
    console.log(data.finances)
  }


   const [transactionData, setTransactionData] = useState()

  useEffect(()=>{
   const setTD = []
   if(data){
     data.finances.map((f,i) => {
    if(f.domain === 'live'){
      setTD.push({i,
              time: {start:f.created_at, completed:f.paid_at},
              status:{response: f.response, amount: f.amount},
            ...f})
      }
     })
     console.log(setTD )
     setTransactionData(setTD);
    }
  }, [data])

const [view, setView] = useState(false)
const [withdrawalsData, setWithdrawalsData] =useState()

const {data: withdrawalData, refetch} = useQuery(getWithdrawals,
  {variables: {type: "admin"},
  onCompleted: data => console.log(data),
  onError: err => console.log(err),
})

useEffect(()=>{
    const setWD = []
   if (withdrawalData) {
     console.log(withdrawalData)
     withdrawalData.withdrawals.map((f, i) => {
      setWD.push({...f})
     })
     console.log(setWD )
     setWithdrawalsData(setWD.reverse());
    }
}, [withdrawalData])

const [cfm, setCfm] = useState(false);
const [res, setRes] = useState();
const [fb, setFb] = useState(false);

 const [massUserAction] = useMutation(Mass_Update, {
    onCompleted: data => fbHnd(data),
    onError: error => fbHnd(error)
  })

  const fbHnd = (res) =>{
    console.log(res)
    setFb(true); 
    setWithdrawalsData([])
    setCheckeds({state: false})
    refetch();
    setTimeout(()=> {setCfm(false); setFb(false)}, 3000);
  }

  const massHandler = async () =>{
     console.log(fChecks)

     const users = JSON.stringify(fChecks);
     const action = res === 2 ? true : res === 3 && false;
     console.log(users)
     console.log(res)

     massUserAction({variables:{type: res, users, action}})

  }

  return (<>

       <Modal as={Modal.Dialog}  centered show={cfm} onHide={e=> setCfm(false)} className="modal-tertiary">
             <Modal.Body>
                  <div className="confirm-element">
                <h3>{fb? <>  <FontAwesomeIcon icon={faCheckCircle} className="me-1" />  Success  </>: 'Are you Sure?'}</h3>

                {!fb && <div className="btn-div">
                <button className='btn-cancel'  onClick={e=> setCfm(false)}>Cancel</button> 
                <button className='btn-submit'  onClick={e=> massHandler()} aria-label="Submit">Submit</button>
                </div>}

                  </div>
              </Modal.Body>

        </Modal>

      <article>
      <Container className="px-0">
      <Row className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item>
               <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>

            <Breadcrumb.Item>brainballer</Breadcrumb.Item>
            <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
          </Breadcrumb>
          <h4> {view? 'Withdrawals':'Transactions'} <button onClick={e=>setView(!view)}> Change </button> </h4>
          <p className="mb-0">{!view ? 'You have no control over this data as this is directly from the Third Party payment Provider. Cheers, a reflief to the Datastore!': 'Withdrawal Activities'}</p>

         {checkeds.state && <div>
               <div>
                  {checkeds.count} selected
              </div>
               <div className="action-btns">

                <button onClick={e=>{setCfm(true); setRes(9)}} > <FontAwesomeIcon icon={faUserAltSlash} />  Reject Request</button> 
                <button onClick={e=>{setCfm(true); setRes(10)}} > <FontAwesomeIcon icon={faUserSlash} />  Set to Paid</button> 
                </div>
             </div>} 
      
        </Col>
        {/* <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
        </div> */}
      </Row>
      

     <div className="view-cnt">
        <div className={`transactions-cnt ${!view ? 'show':''}`}>
          {transactionData? <DataTable columns={columns} data={transactionData} />:"loading..."}
        </div>

         <div className={`withdrawal-cnt ${view ? 'show':''}`}>
          {withdrawalsData? <DataTable columns={columnWD} data={withdrawalsData} />:"loading..."}
        </div>
      </div> 
   
      {/* <TransactionsTable /> */}
    </Container>
    </article>
  </>);
};
