import React, {useState, useEffect} from 'react'
import { Row, Col, Card, Modal, Button, Container, Form,
InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { imageDisplay, PubIcon } from '../helper';
import Profile3 from "../../../assets/img/technologies/placeholder.png";
import {
    gql,
    useQuery,
    useMutation,
    useSubscription
} from '@apollo/client'
import $ from 'jquery'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';


const Iframe = props =>{

  useEffect(() => {
    if(props.id)console.log(props)
  }, [props.id])

return <div class="iframe">
  {props.id && <> 
  {/* <iframe width="100%" height="100%"
  src={`https://www.youtube.com/embed/${props.id}?autoplay=1&mute=1&controls=0&loop=1&disablekb=1&fs=0&modestbranding=1&enablejsapi=1&origin=https://brainballer.com`}/>  */}
    <video muted autoplay loop style={{width: '100%'}}>
      <source src={`https://drive.google.com/uc?export=download&id=${props.id}`} type='video/mp4' />
      {/* <source src={`https://drive.google.com/uc?export=download&id=1Kyg0XBDSdVuFOFmrFC0qPEJfLCisLgmW`} type='video/mp4' /> */}
    </video>
  </>}
    <div className="iframe-overlay"></div>
  </div>
}

const Get_Game = gql`
          query GetGame($id: ID!, $type: String){
               getGame(id: $id, type: $type) {
                id
                title
                questions {
                  id
                  questionText
                  visualType
                  visual
                   hint
                   time
                   answerOptions {
                     answerText
                     isCorrect
                   }
                }
              }}
`
const Add_Question = gql` 
     mutation AddQuestion($game: ID, $visual: Upload, $questionText: String!,
           $time: Int, $hint: String,
           $visualType: String, $answerOptions: String){
                addQuestion(questionInput:{
    visual: $visual,
      questionText: $questionText,
         game: $game,
         time: $time,
        visualType: $visualType,
         hint: $hint
        },
         answerOptions: $answerOptions
         ){
             id
          questionText
          hint
          answerOptions{
            answerText
            isCorrect
          }
       }
     }
`

const Update_Question = gql`     
     mutation UpdateQuestion($id: ID!, $game: ID, $questionText: String!,
       $time: Int, $hint: String, $answerOptions: String){
       updateQuestion(id: $id, questionInput:{
         questionText: $questionText,
         game: $game,
         time: $time,
         hint: $hint,
       },
         answerOptions: $answerOptions){
              id
          questionText 
          hint
          answerOptions{
            answerText
            isCorrect
          }
       }
     }
`

const Delete_Question = gql`
     mutation DeleteQuestion($id: ID!){
       deleteQuestion(id: $id)
     }
`

export const InputGrp = props => <Form.Group className="mb-1 options">
  <InputGroup>
    <InputGroup.Text>
      <Form.Check
        type="radio"
        name="isCorrect"
        id="answer"
        htmlFor="answer"
        onChange={props.onChange}
        required
      />
    </InputGroup.Text>
    <Form.Control type="text" name="answerText" maxlength='22' placeholder="enter option" required/>
  </InputGroup>
</Form.Group>



const inputs = []

function Question(props) {

  const [visualImg, setVisualImg] = useState(2)

// display uploaded image
  const [upImg, setUpImg]= useState(Profile3)
    const imageUploaded = async e=>{

      setFmData({...fmData, visual : e.target.files[0]})

      const img = await imageDisplay(e);
        img.onloadend = (e) => {
          setUpImg([img.result])
        };
  }


    const { data, loading, error, refetch} = useQuery(Get_Game, {
        variables:{id: `${props.game}`, type: 'admin' }
    })


    const [addQuestion] = useMutation(Add_Question, {
      onCompleted: data => refetch(),
      onError: err => console.log(err)
    })

     const [updateQuestion] = useMutation(Update_Question, {
      onCompleted: data => refetch(),
      onError: err => console.log(err)
    })

     const [deleteQuestion] = useMutation(Delete_Question, {
      onCompleted: data => refetch(),
      onError: err => console.log(err)
    })

const [fmData, setFmData] = useState()
const [gameData, setGameData] = useState()

   useEffect(()=>{
      if(data){
        console.log(data.getGame)

        setGameData({...gameData, ...data.getGame, questions:[]})

        setTimeout(() => {
          setGameData({...gameData, ...data.getGame, questions: data.getGame.questions})
        }, 100);

        setFmData({...fmData, game: data.getGame.id})
    }}, [data])

    // const [showDef, setShowDef] = useState(props.show);

    useEffect(()=>{
        $('.modal-dialog-centered.modal-dialog').addClass('modal-xl')
    }, [props])



  const inpChange = e => {
    console.log("We here")
  }

useEffect(() => {
  inputs.length=0;
  for (var i = 0; i < 4; i++) {
    inputs.push(<InputGrp key={i} onChange={inpChange} />)
  }
}, [])
 

const [visualTypeVal, setVisualTypeVal] = useState()

const submitHandler =async e =>{
  e.preventDefault();

  const {time, visualType, visual, questionText, hint} = e.target;

  if(Number(visualType.value) === 1){
    setVisualTypeVal(JSON.stringify({visual:visual.value, visualType: visualType.value}))
  }else{
    setVisualTypeVal(JSON.stringify({visualType: visualType.value}))
  }

  const answerOptions = [];
  const {isCorrect} = e.target;
  const answerTexts = [...e.target.answerText];
  await answerTexts.map((ans, i)=>{
    answerOptions.push({answerText: ans.value, isCorrect: isCorrect[i].checked})
  })

  await setFmData({...fmData, time: +(time.value),
     hint: hint.value,  visualType: visualTypeVal ,
    questionText: questionText.value, answerOptions: JSON.stringify(answerOptions)})

  console.log(fmData);
    
  addQuestion({variables: 
   {...fmData, time: +(time.value),
     visualType:  visualTypeVal,
    questionText: questionText.value,hint: hint.value, answerOptions: JSON.stringify(answerOptions)
  }})
}

const updateHandler =async e =>{
  e.preventDefault();


  console.log("I got submitted")

  const {id, time, visualType, questionText, hint} = e.target;

  console.log(id)

   const answerOptions = [];
  const {isCorrect} = e.target;
  const answerTexts = [...e.target.answerText];

  await answerTexts.map((ans, i)=>{
    answerOptions.push({answerText: ans.value, isCorrect: isCorrect[i].checked})
  })


  updateQuestion({variables: {id: id.value, time: +(time.value),
    questionText: questionText.value, hint: hint.value, 
    answerOptions: JSON.stringify(answerOptions)
  }})
}


const deleteQuestionHandler = (e) =>{
  deleteQuestion({variables:{id: e}})
}


const [vidFootage, setVidFootage] = useState()
    
    return (<Modal as={Modal.Dialog} className="modal-secondary qst-modal" centered show={props.show} onHide={props.toggle}>
        {gameData == undefined?"Loading": <><Modal.Header>
            <Modal.Title className="h2">{`${gameData.title} Questions`}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={props.toggle} />
              </Modal.Header>

              <Modal.Body>
                <Row className="qst-cards">

                <Col xs={10} className="set-col col-lg-3 col-md-4 col-sm-12">
                        <Form onSubmit={submitHandler}>
                            <h5>Add Question</h5>
                            <div className="question-form">
                                <Form.Group className="mb-3">
                                  <Form.Label></Form.Label>
                                  
                                  {visualImg === 0?<>
                                  <div className="qst-img mb-3">
                                    <img src={upImg} alt="" onClick={e=>$("#questionVisual").trigger("click")}/>
                                  </div>
                                  <input id="questionVisual" type="File" name="visual" hidden onChange={imageUploaded} required/>
                                      </>
                                    :visualImg === 1?<><div>
                                        <Iframe id={vidFootage} />
                                     </div>

                                       <Form.Group className="mt-3">
                                <InputGroup>
                                  <InputGroup.Text>
                                  {/* <FontAwesomeIcon icon={"fa-link"} /> */}
                                  </InputGroup.Text>
                                    <Form.Control id="questionVisual" type="text" name="visual" onChange={e=>{
                                      setVidFootage(e.target.value)}} placeholder="enter footage id" required/>
                                  
                                   </InputGroup>
                                   </Form.Group>
                                     </>:<>
                                      <div className="qst-img mb-3">
                                       <img style={{filter: 'grayscale(100%)'}} src={upImg} alt=""/>
                                     </div>
                                     </>
                                     }
                                  
                               
                                </Form.Group>


                                <Form.Group className="mb-3">
                                  <Form.Label>Visual Type</Form.Label>
                                 

                                <div className="visual-type">

                                   <Form.Check
                                   defaultChecked
                                    type="radio"
                                    value={2}
                                    label="No Visual"
                                    name="visualType"
                                    id="noVisual"
                                    htmlFor="noVisual"
                                    onChange={e=>setVisualImg(2)}
                                />
                                
                                      <Form.Check
                                    type="radio"
                                    value={0}
                                    // defaultValue={ans.isCorrect ? true : false}
                                    label="Image"
                                    name="visualType"
                                    id="imageType"
                                    htmlFor="imageType"
                                    onChange={e=>setVisualImg(0)}
                                />

                                    <Form.Check
                                    type="radio"
                                    value={1}
                                    label="Video Footage"
                                    name="visualType"
                                    id="footage"
                                    htmlFor="footage"
                                    onChange={e=>setVisualImg(1)}
                                />
                                </div>

                                </Form.Group>


                  <Form.Group className="mb-3">
                    <InputGroup>
                      <InputGroup.Text id={`qstnTime`}>15s</InputGroup.Text>
                      <div className="form-control" style={{ display: 'flex'}}>
                      <Form.Control type="range" name="time" min="10" max="60" defaultValue="15" className="slider" id="myRange" onChange={e => $(`#qstnTime`).text(`${e.target.value}s`)} />
                      {/* <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback> */}
                      </div>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control as="textarea" name="questionText" placeholder="enter question" rows="2" />
                    {/* <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback> */}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" name="hint" placeholder="question hint" />
                    {/* <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback> */}
                  </Form.Group>


                                
                                {inputs.map(op => op)}
                  {/* {{for (var i=0; i<4; i++) {<InputGrp obj={objects[i]} key={i}>}} */}
                  
                                                
                  
                  
                            </div>
                            <Button variant="secondary" type="submit" className="mt-3"> 
                                {"Add Question"}
                            </Button>
                        </Form>
                </Col>




                <Col xs={10} className="qsts-col col-lg-9 col-md-8 col-sm-12">
                  <Row>
               {gameData.questions.map((qst, i) => <Col key={i} xs={12} className="mb-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <Form onSubmit={updateHandler}>
            <Card className={"question-card"}>
              <Card.Header className="header">
                <Form.Group className="mb-3">
                        <Form.Label>
                          Question {i+1}

                           <Button variant="danger" onClick={e=>deleteQuestionHandler(qst.id)} className="mt-3">
                             <FontAwesomeIcon icon={faTrashAlt} className="me-1" />
                           </Button>
                        </Form.Label>
                        <div className="qst-img mb-3">
                            {qst.visualType === 0?
                              <img src={qst.visual ? qst.visual : Profile3} alt="" />:
                                  <Iframe id={qst.visual} />
                          }
                        </div>
                </Form.Group>
            </Card.Header>

                <Card.Body>
                       <Form.Group className="mb-3">
                         <InputGroup>
                           <InputGroup.Text id={`qstnTime${i+1}`}>{qst.time}s</InputGroup.Text>
                          <div className="form-control" style={{ display: 'flex'}}>
                           <Form.Control type="range" name="time" min="10" max="60" defaultValue={qst.time} className="slider" id="myRange" onChange={e=>$(`#qstnTime${i+1}`).text(`${e.target.value}s`)}/>
                           <Form.Control type="text" name="id" value={qst.id} className="slider" hidden/>
                         {/* <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback> */}
                            </div>
                           </InputGroup>
                       </Form.Group>


                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" name="questionText" defaultValue={qst.questionText} rows="2" />
                        {/* <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback> */}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control type="text" name="hint" defaultValue={qst.hint} placeholder="hint" />
                        {/* <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback> */}
                    </Form.Group>

                {qst.answerOptions.map((ans, j)=>{
                return <Form.Group key={j} className="mb-1 options">
                  <InputGroup>
                    <InputGroup.Text>
                            {/* <div className="published-btn"> */}
                                <Form.Check
                                    defaultChecked={ans.isCorrect ? true : false}
                                    type="radio"
                                    // defaultValue={ans.isCorrect ? true : false}
                                    // label="Default radio"
                                    name="isCorrect"
                                    id="answer"
                                    htmlFor="answer"
                                />
                                {/* <input type="radio" className="toggle" name="published" id={`toggle${j}`} defaultChecked={ans.isCorrect ? true : false} />
                                <label htmlFor={`toggle${j}`} className="toggleWrapper">
                                    <div className="toggle"></div>
                                </label> */}
                            {/* </div> */}
                    </InputGroup.Text>
                    <Form.Control id={`ansText${j}`} type="text" name="answerText"
                    maxlength='22'
                    isInValid placeholder="" defaultValue={ans.answerText} />
                  </InputGroup>
              </Form.Group>
               })
              }

                  <Form.Group className="mt-3">
                        <button type="submit" className="btn btn-dark">Submit</button>
                    </Form.Group>
                </Card.Body>
                  </Card>
                   </Form> </Col> )}
                

                     </Row>
                     </Col>
                 </Row>
              </Modal.Body>

            
               
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={props.toggle}>
                    Close
                 </Button>
            </Modal.Footer></>}
     </Modal>
)
}


export default Question