import React, { useState, useEffect } from "react";
import { Form, InputGroup } from '@themesberg/react-bootstrap';
import moment from "moment-timezone";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";



const Datepicker = (props) => {
    const yesterday = moment().subtract(1, 'day');

    const [date, setDate] = useState();
    // console.log(defaultValuequizData.subCategory})

    useEffect(() => {
        if(props.defaultValue){setDate(props.defaultValue)}
    }, [props.defaultValue])


    const valid = ( current ) => {
        return current.isAfter( yesterday );
    };

    const validity = () =>{
        const time = props.validity;
        const t = moment().diff(time) > 0
        console.log(t)
        return t
    }

    const ppts = props;

    return (
            <Form.Group className="mb-3">
                <Datetime
                    closeOnSelect={false}
                    onChange={setDate}
                    // inputProps={{
                    //     type: 'text',
                    //     name: props.name,
                    //     placeholder: "dd/mm/yyyy",
                    //     onChange: props.onChange,
                    //     onFocus: props.onChange,
                    //     // value: (date ? moment(date).format("DD/MM/YYYY"):" "),
                    //     required: true,
                    //     autoComplete: "off"
                    // }}

                    isValidDate={valid}

                    renderInput={(props, openCalendar) => (
                        <Form.Control
                            required
                            type="text"
                            name={ppts.name}
                            defaultValue={ppts.defaultValue}
                            value={date ? new Date(date).toLocaleString().replace(",", ""): " "}
                            placeholder="mm/dd/yyyy"
                            onFocus={openCalendar}
                            onChange={() =>{}}
                            autoComplete='off'
                            />
                    )} 
                    />
            </Form.Group>
    );
}



const Timepicker = (props) => {
    // const yesterday = moment().subtract(1, 'day');

    const [birthday, setBirthday] = React.useState("");
    // console.log(props.dateformat)
    const ppts = props


    return (
        <Form.Group className="mb-3">
            <Datetime
               dateFormat={false}
                inputProps={{
                    name: props.name,
                    placeholder: "01:00 AM",
                    onChange: ppts.onChange
                }}
                />
        </Form.Group>
    );
}


export {Datepicker, Timepicker}
// render(<Datepicker />)