import React from 'react'




const imageDisplay = async e => {
    const file = e.target.files[0];
    console.log(file)
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    // const res = [];
    return reader;

}



const PubIcon = props =>{

    return <div className={props.className}>
        <input type="checkbox" className="toggle" id={`toggle${props.id}`} defaultValue={props.defaultValue ? true : false} onChange={props.onChange}/>
        <label htmlFor={`toggle${props.id}`} className="toggleWrapper">
            <div className="toggle"></div>
        </label>
    </div>
}


export { imageDisplay, PubIcon}