
import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faTimesCircle,
  faEye, faBullhorn,
  faUserShield, faUserSlash, faUserCheck, faUser,
  faUserTimes, faTrashAlt, faEllipsisH, faCheckCircle, faUserAltSlash, faWallet
} from "@fortawesome/free-solid-svg-icons";
import { Alert, Col, Row, Table, Image, Modal, Container, Button } from '@themesberg/react-bootstrap';

import Documentation from "../components/Documentation";
import {
  gql,
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client'
import USAFlag from "../assets/img/flags/united-states-of-america.svg";
import CanadaFlag from "../assets/img/flags/canada.svg";
import UKFlag from "../assets/img/flags/united-kingdom.svg";
import FranceFlag from "../assets/img/flags/france.svg";
import JapanFlag from "../assets/img/flags/japan.svg";
import GermanyFlag from "../assets/img/flags/germany.svg";
import DataTable from "./components/userInformation/dataTable";
import { Form } from '@themesberg/react-bootstrap';
import $ from 'jquery'
import TimeAgo from "../middleware/timeAgo";



const getUsers = gql `
  query {
    users {
       id
       avatar
       name
       username
       cache
       displayName
       point
        state
        country
        banned
        verified
        createdAt
        balance
    }
  }`


const getUser = gql `
    query User($id: ID){
      user(id: $id){
        id
        avatar
        displayName
        name
        regMode
        username
        account
        bank
        accountnumber
        ip
        cache
        country
        state
        mobile
        balance
        point
        allTime
        banned
        authorized
        verified
        code
        ref_token
        referral{
          id
          displayName
        }
        createdAt
        updatedAt
        account
        referrals{
          id
        }
        gamesCreated{
          id
        }
        games {
          published
        }
        friends {
          displayName
        }
        logs{
          id
            type
              details
              createdAt
        }
      }
    }
`


const Mass_Update = gql `
    mutation MassUserAction($type: Int!, $action: Boolean,
       $users: String, $amount: Float, $content: String) {
        massUserAction(type: $type, users: $users, action: $action,
          amount: $amount, content: $content)
    }
    `

const New_Msg = gql`
    mutation NewMessage($receiver: String, $content: String! ){
      newMessage(receiver: $receiver, content: $content)
    }
`

var usrID




export default () => {
  const [showAlert, setShowAlert] = useState({show: false});

   useEffect(()=>{
    if (showAlert.show) setTimeout(() => setShowAlert({show: false}), 8000)
   }, [showAlert])
  
  const checkBox = [];
  const [checkeds, setCheckeds] = useState({state: false, count: 0})
  const [fChecks, setFChecks] = useState([])

  const checkedHandlers = (e, id) =>{
    const chks = $(".tableChecks").find("input:checkbox:checked");

    console.log(chks.length)
    // setCheckeds({...checkeds, count: chks.length});

    if(chks.length > 0){
      setCheckeds({count: chks.length, state: true});
    }else{
      setCheckeds({state: false});
    }

    if(e.target.checked){
      checkBox.push(id)
    } else if (!e.target.checked) {
      const index = checkBox.indexOf(id)
      if (index > -1) {
        checkBox.splice(index, 1)
      }
    }

    setFChecks(checkBox)
    console.log(checkBox)
  }

const columns = [
    // { dataField: "id", text: "ID", hidden: true },
    { dataField: "id", text: " ", searchable: false

    // <Form.Check 
    //       onChange={e=>{
    //         $(".tableChecks").find("input[type=checkbox]").prop('checked', e.target.checked);
    //         if(e.target.checked === false){
    //           setCheckeds({state: false});
    //           checkBox.length = 0;
    //         }else{
    //           setCheckeds({state: true});
    //         }
    //       }} />
          ,  

    formatter: (cell) =><div className={`dashboard-check`}>
      <Form.Check className="tableChecks" onChange={e=>checkedHandlers(e, cell)} value={cell}/>
      </div>, csvExport: false
    },
    {
      dataField: "details",
      text: "USER",
      formatter: (cell) =><>
       <a className="d-flex align-items-center card-link">
         <img src={`https://res.cloudinary.com/brainballer/image/upload/v1621775917/avatar/${cell.avatar?cell.avatar:'def'}.png`} className="user-avatar rounded-circle me-3" />
            <div className="d-block">
            <span className="fw-bold">{cell.displayName} {cell.verified && <FontAwesomeIcon icon={faCheckCircle} />} </span>
            <div className="small text-gray">{cell.name}</div>
            <div className="small text-gray">
              {(cell.cache === 'admin') && <span className={`text-danger`}>● </span>}
              {(cell.account == 1) && <span className={`text-warning`}>● </span>}
               {cell.username}</div>
            </div></a>
      </>, 
  filterValue: (cell) => {
    let arr = Object.keys(cell).map((k) => cell[k])
    return arr;
  }, sort: true,
   csvFormatter: (cell) => `${cell.displayName + ' - '+ cell.name + ' - '+ cell.username}`
  },
    { dataField: "location", text: "Country, State",
  formatter:(cell) =><>
     <div className="d-block">
            <div className="small text-gray">{cell.state}</div>
            <div className="small text-gray">{cell.country}</div>
            <div className="small text-gray">
              {new Date(cell.joined).toLocaleString()} 
              </div>
      </div>
  </>, 
  filterValue: (cell) => {
    let arr = Object.keys(cell).map((k) => cell[k])
    return arr;
  }, 
  sort: true,
  csvFormatter: (cell) => `${cell.state + ' - '+ cell.country + ' - '+ new Date(cell.joined).toLocaleString()}`

},
    { dataField: "point", text: "Points",sort: true },
    { dataField: "balance", text: "Balance",sort: true },
//   { dataField: "banned", text: "Account Status" },
//   { dataField: "options", text: "Option" },
  { dataField: "option", text: "Options", formatter: (cell) =><> 
    <div role="group" className="dropdown btn-group">
      <button onClick={()=>$(`#opt${cell}`).toggle()} aria-haspopup="true" aria-expanded="false" type="button" className="text-dark m-0 p-0 dropdown-toggle dropdown-toggle-split btn btn-link">
        <span className="icon icon-sm">
          <FontAwesomeIcon icon={faEllipsisH} /> </span></button>


            <div id={"opt"+cell} x-placement="bottom-end" aria-labelledby="" className="dropdown-menu shw" style={{position: 'absolute', top: '0px', left: '0px', margin: '0px', right: 'auto', bottom: 'auto', transform: 'translate(-169.5px, 30px)'}} data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom-end">
             
             
             <a  className="dropdown-item" role="button" onClick={()=>tableOptions(cell, 1)}>
               <FontAwesomeIcon icon={faEye} /> View Details</a>
            
            
            {/* <a  className="dropdown-item" role="button" onClick={()=>tableOptions(cell, 2)}>
              <FontAwesomeIcon icon={faUserShield} /> Authorize Account</a>
                  
                  
            <a  className="text-danger dropdown-item" role="button" onClick={()=>tableOptions(cell, 3)}>
              <FontAwesomeIcon icon={faUserTimes} /> Suspend Account</a>
              
              
          <a className="text-danger ml-2 ms-2 card-link" onClick={()=>tableOptions(cell, 4)}>
           <FontAwesomeIcon icon={faTrashAlt} /> Remove Account </a>  */}
              </div>
          </div>
          </>, csvExport: false}
];

  
const rowClasses = (row, rowIndex) => {
  let classes = 'custom-row-class btn-danger';

  // if (rowIndex > 2) {
  //   classes = 'btn-danger';
  // }

  return classes;
};




  const {
    data: users,
    loading: loadingUsers,
    refetch: refetchUsers
  } = useQuery(getUsers,{
    onCompleted: data => setShowAlert({show: true, title:'Success', 
        content:'Records fetched successfully'}),
  })


  const [userData, setUserData] = useState()
  useEffect(()=>{

    const setUD = []

   if(users){
     console.log(users.users)
     users.users.map(e=>{
      let details = {avatar:e.avatar,
              displayName: e.displayName,
              name: e.name, cache: e.cache,
              account: e.account, verified: e.verified,
              username: e.username}
      
      setUD.push({details,
              location:{country: e.country, state: e.state, banned: e.banned,
            joined: e.createdAt},
              option:e.id,
            ...e})
     })
     console.log(setUD )
     setUserData(setUD);
    }
  }, [users])



  

const [toGet, setToGet] = useState()
const [getuser, setGetuser] = useState({
  status: false
})

  const [get_User, {
    data: user,
    called,
    loading: loadingUser,
    refetch: refetchUser
  }] = useLazyQuery(getUser, {
    variables: {
      id: toGet
    },
    onCompleted: data => setShowAlert({show: true, title:'Success', 
        content:'user account information fetched'}),
    onError: err => setShowAlert({show: true, title:'Error', 
        content:'oops! unable to fetch at this time, please try again'}),
    // skip: getuser.status
  })
  

  useEffect(()=> {
    console.log(user)
    if(user)setGetuser(user.user)
  }, [user])

    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);

  const tableOptions = (e, t) => {
    if(t === 1){
      setToGet(e)
      setShowDefault(true)
      get_User(e)
    }
  }


  const [cfm, setCfm] = useState(false);
  const [res, setRes] = useState();
  const [fb, setFb] = useState(false);

  const [massUserAction] = useMutation(Mass_Update, {
    onCompleted: data => fbHnd(data),
    onError: error => fbHnd(error)
  })

const [newMessage] = useMutation(New_Msg, {
  onCompleted: data => fbHnd(data),
  onError: error => fbHnd(error)
})

  const fbHnd = (res) =>{
    console.log(res)
    setFb(true); 
    setUserData([])

    setCheckeds({state: false})
    refetchUsers();
    setTimeout(()=> {setCfm(false); setFb(false)}, 3000);
  }


  const [showMini, setShowMini] = useState({show: false})
  const handleMini = () => setShowMini({show: false})

  const massHandler = async (e) =>{
     console.log(fChecks)

     const users = JSON.stringify(fChecks);
     const action = res === 2 ? true : res === 3 && false;
     console.log(users)
     console.log(res)

     let outRes;
    if(e){ if(e.target.amount || e.target.content){
      e.preventDefault();
      const {amount, content} = e.target;

      outRes = await content ? ({content:  content.value}) : ({amount: Number(amount.value)})
      console.log(users, {...outRes})
      if(content && content.value){
        return newMessage({variables:{receiver: users , ...outRes}});
      }
    }
}
     console.log(outRes)


     massUserAction({variables:{type: res, users, action, ...outRes}})
  }



  return (
    <>
     <Alert
        variant="light"
        show={showAlert.show}
        onClose={() => setShowAlert({...showAlert, show: false})}>

        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faBullhorn} className="me-1" />
            <strong>{showAlert.title}! </strong>  
            {showAlert.content}
          </div>
          <Button variant="close" size="xs" onClick={() => setShowAlert({...showAlert, show: false})} />
        </div>
      </Alert>

        <Modal as={Modal.Dialog}  centered show={cfm} onHide={e=> setCfm(false)} className="modal-tertiary">
             <Modal.Body>
                  <div className="confirm-element">
                <h3>{fb? <>  <FontAwesomeIcon icon={faCheckCircle} className="me-1" />  Success  </>: 'Are you Sure?'}</h3>

                {!fb && <div className="btn-div">
                <button className='btn-cancel'  onClick={e=> setCfm(false)}>Cancel</button> 
                <button className='btn-submit'  onClick={e=> massHandler()} aria-label="Submit">Submit</button>
                </div>}

                  </div>
              </Modal.Body>

        </Modal>

    <article>
      <Container className="px-0">
        <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
          <Col className="d-block mb-4 mb-md-0">
            <h1 className="h2">Users</h1>
            <div>
            <p className="mb-0">
              Brainballer User Data
            </p>

             {checkeds.state && <div>
               <div>
                  {checkeds.count} selected
              </div>
               <div className="action-btns">
                {/* <button>Make Admin</button> 
                <button>Ban</button>  */}
                <button onClick={e=>{setCfm(true); setRes(2)}} > <FontAwesomeIcon icon={faUserSlash} />  Ban</button> 
                <button onClick={e=>{setCfm(true); setRes(3)}} > <FontAwesomeIcon icon={faUserAltSlash} />  UnBan</button> 
                <button onClick={e=>{setCfm(true); setRes(4)}} > <FontAwesomeIcon icon={faUserCheck} />  Authorize</button> 
                <button onClick={e=>{setCfm(true); setRes(5)}} > <FontAwesomeIcon icon={faUserTimes} />  Revoke Access</button> 
                <button onClick={e=>{setCfm(true); setRes(6)}} > <FontAwesomeIcon icon={faUserShield} />  Make Admin</button> 
                <button onClick={e=>{setCfm(true); setRes(7)}} > <FontAwesomeIcon icon={faUser} />  Revoke Access</button> 
                <button onClick={e=>{setCfm(true); setRes(1)}}><FontAwesomeIcon icon={faTrashAlt} />   Delete</button> 

                <button onClick={e=>{setShowMini({show: true, title: 'Fund Wallet', type: 1}); setRes(8)}}> <FontAwesomeIcon icon={faWallet} />  Fund </button> 
                <button onClick={e=>{setShowMini({show: true, title: 'Send Message', type: 2}); setRes(12)}}> <FontAwesomeIcon icon={faWallet} />  Message </button> 

                </div>
             </div>} 
            </div>
          </Col>
        </Row>

    { userData ? <DataTable columns={columns} data={userData} rowClasses={ rowClasses } /> : "Loading"}
      </Container>


       <Modal as={Modal.Dialog}  centered show={showDefault} onHide={handleClose} className="modal-tertiary">
              <Modal.Header>
                <Modal.Title className="h6">{"User Details"}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
              </Modal.Header>

              <Modal.Body>
                <div className='user-view'>
                  <div className="stats"> 
                    <ul>
                      <li> {getuser.banned && <FontAwesomeIcon icon={faUserAltSlash} /> } </li>
                      <li> <span>{getuser.balance}</span> Balance </li>
                      <li> <span>{getuser.point}</span> Points </li>
                      <li> <span>{getuser.allTime}</span> Alltime</li>
                    </ul>
                  </div>
                   <div className="d-flex align-items-center card-link basic">
                    <img src={`https://res.cloudinary.com/brainballer/image/upload/v1621775917/avatar/${getuser.avatar?getuser.avatar:'def'}.png`} className="user-avatar rounded-circle me-3" />
                        <span>id: #{getuser.ref_token}</span>
                        <div className="d-block">
                        <span className="fw-bold">
                          {getuser.cache === 'admin' && <FontAwesomeIcon icon={faUserShield} />}
                          {getuser.displayName} {getuser.verified && <FontAwesomeIcon icon={faCheckCircle} />} </span>
                        <div className="small text-gray">{getuser.name}</div>
                        <div className="small text-gray">{getuser.username}</div>
                      </div>
                   </div>

                  <div className="others">
                      <ul>
                        <li> <span>Country: {getuser.country} </span> <span>State: {getuser.state} </span></li>
                        <li> <span>Mobile: {getuser.code, getuser.mobile} </span> <span>Referrals: {getuser.referrals && getuser.referrals.length} Referrals </span></li>
                        <li> <span>Participated In: {getuser.games && getuser.games.length} Games  </span> <span>{getuser.gamesCreated && getuser.gamesCreated.length} Games Created </span></li>
                      </ul>
                  </div>
                  
                  <div className="log-activity">
                    {getuser.logs && getuser.logs.map((log, i) => <p key={i}>
                      <span className='type'>{log.type}</span>
                      <span className='details'>{log.details}</span>                      
                      <span className='date'>{new Date(log.createdAt).toLocaleString()}</span>                      
                    </p>)}
                  </div>

                </div>
                
              </Modal.Body>

        </Modal>

                <Modal as={Modal.Dialog}  centered show={showMini.show} onHide={handleMini} className="modal-tertiary">
              <Modal.Header>
                <Modal.Title className="h6">{showMini.title}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleMini} />
              </Modal.Header>
              <Modal.Body>
                  <form onSubmit={massHandler}>
                    {showMini.type === 1 && <input type="number" name="amount" className="form-control" placeholder={`enter amount`}/>}
                    {showMini.type === 2 && <textarea className="form-control" name="content" id="" cols="30" rows="10" placeholder="type message"></textarea>}
                    <button>Submit</button>
                  </form>
              </Modal.Body>
              </Modal>

    </article>
    </>
  );
};