
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import {
  gql,
  useMutation,
  useSubscription
} from '@apollo/client'
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { isLoggedInVar, authVar } from "../../middleware/cache";



const Login_User = gql `
    mutation Login($username: String!, $password: String!){
        login(loginInput: {username: $username, password: $password}){
           token
           tokenExpiration
           user {
             avatar
             displayName
             cache
           }
        }
    }
`;


export default function SignIn (){


    const [login] = useMutation(Login_User, {
        onCompleted: ({login}) => {
          if(login.user.cache !== 'admin') {
            alert("Unauthorized");
            throw new Error("Unauthorized")};

          localStorage.setItem('token', JSON.stringify(login.token));
          localStorage.setItem('auth', JSON.stringify(login.user));
          isLoggedInVar(true);
          authVar(login.user)
        },
        onError: error => console.log(error)
    })


    const submitHandler = e =>{
      e.preventDefault();
        const {password, username}=e.target;
        const $_password = password.value;
        const $_username = username.value;

      login({variables:{password: $_password, username: $_username}});                       
    }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Welcome Admin</h3>
                </div>
                <Form className="mt-4" onSubmit={submitHandler}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required name="username" type="email" placeholder="username@mail.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" name="password" placeholder="Password" />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    </div>
                </Form>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
